
import header from '../assets/header/freiburg.jpg';

function Header () {
    return(
        <div>
            <img src={header} className="img-fluid " alt="..."/>
        </div>
    )
}

export default Header;