import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { IMAGE_URL } from '../App';


function Login() {

    const [values, setValues] = useState({ email: "", password: "" });
    const [forgotPassword, setForgotPassword] = useState(false);
    const [email, setEmail] = useState("")


    return (
        <div className='center-container container-fluid no-text-cursor'>
            <div className='bg-white px-5 rounded-5 shadow-lg'>
                <div className='hover'>
                    <div className='col logo-container pt-4'>
                        <img className="logo mb-4 mt-3" src={`${IMAGE_URL}/logo/logo-ohne-schriftzug.png`} alt="logo" />
                    </div>
                    <div className='col text-dark rakete-font logo-container fs-5 logo-text'>
                        <b className="rakete-rot">RAKETE</b>
                        <b>FREIBURG</b>
                    </div>
                </div>
                    <Form>
                    <p className='fs-7 text-center mt-3'>Melde dich mit der Email an, welche bei uns hinterlegt ist.</p>
                        <Form.Group className="mb-2" controlId="formGroupEmail">
                            <Form.Label className='text-uppercase fs-8 fw-semibold rakete-font'>Email</Form.Label>
                            <Form.Control className="form-control-sm" type="text" autoComplete="email" placeholder="max.mustermann@abc.de" name="email" onChange={e => setValues({ ...values, [e.target.name]: e.target.value })} required />
                        </Form.Group>
                        <Form.Group controlId="formGroupPassword">
                            <div className='center mt-4'>
                                <button className="sign-button text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" type="submit">Login</button>
                            </div>
                        </Form.Group>
                        <p className='fs-7 text-center mt-3'>Du kannst dich nicht anmelden? Wende dich bitte an deine Leitung.</p>
                    </Form>
            </div>
        </div>
    );
}

export default Login;
